.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
    user-select: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

footer a {
    text-decoration: none;
    color: white;
    font-size: calc(10px + 1vmin);
    transition: all ease-in-out 0.1s;
}

footer a:hover {
    color: #66ccff;
    font-size: calc(12px + 1vmin);
}